<script setup lang="ts">
import { ROUTE_NAV } from '@/shared/config'
import { urlCache } from '@/shared/lib/urlCache'
import IconMiliLogo from '@/shared/public/IconMiliLogo.svg'

import { authEntity } from '@/entities/auth'
import { retentionEntity } from '@/entities/retention'

const FooterComp = defineAsyncComponent(() => import('@/features/landing/ui/LandingFooter.vue'))

useHead({
  link: [
    {
      rel: 'canonical',
      href: 'https://mili.live',
    },
  ],
})

const LANDING_TYPE_FOR_ANALYTICS = 1

const localePath = useLocalePath()
const { $analytics, $facebook } = useNuxtApp()

const toReg = computed(() => localePath(ROUTE_NAV.authWelcome))

onBeforeMount(() => {
  urlCache.urlSet('enter')
  retentionEntity.model.sourcePablicInit()

  const userProps = $analytics.userPropsGet({
    viewtype: window.innerWidth >= 1024 ? 'desktop' : 'mobile',
  })
  const searchParams = urlCache.searchParamsGet('enter')
  if (searchParams) {
    const partnerId = searchParams.p
    if (partnerId) userProps.partner_id = partnerId
    const enterProps = authEntity.model.enterProps()
    $analytics.userProps({ ...userProps, ...enterProps })
    const params = {
      partner_id: partnerId || '',
      utm_source: searchParams.utm_source || '',
      utm_medium: searchParams.utm_medium || '',
      utm_campaign: searchParams.utm_campaign || '',
      utm_term: searchParams.utm_term || '',
      utm_content: searchParams.utm_content || '',
    }
    $analytics.event('landing_shown', params)
  }
})

function onClickButtonSignup(index: number) {
  $analytics.event('landing_signup_pressed', {
    button: index,
    landing_type: LANDING_TYPE_FOR_ANALYTICS,
  })
  $facebook.event('Search', {})
}
</script>

<template>
  <div>
    <section class="bg-landing-img-1 mb-72 text-black lg:mb-256">
      <div class="mx-auto max-w-[1100px] px-16 lg:px-0">
        <div class="lg:mb-216 mb-40 flex justify-center pt-40">
          <IconMiliLogo />
        </div>

        <div class="lg:flex">
          <div class="mb-56 lg:mb-0 lg:w-1/2 lg:pr-80">
            <h1
              class="text-h1-bold lg:text-h1-bold mb-16 lg:mb-24"
              data-test="page_index__title_main"
            >
              Mili — brand new live video chat
            </h1>
            <h2 class="mb-32">
              The easiest way meet incredible people in live video chat! Thousands of new users all
              over the world daily!
            </h2>
            <NuxtLink
              class="bg-raspberry text-h3-medium flex h-[50px] items-center justify-center rounded-full text-white hover:text-white"
              :to="toReg"
              data-test="page_index__button_submit"
              @click="onClickButtonSignup(1)"
            >
              Start Chat
            </NuxtLink>
          </div>
          <div class="lg:w-1/2">
            <NuxtImg
              preload
              src="/landing/screen-catcher-1.webp"
              sizes="lg:550px sm:350px md:450px"
              class="w-full"
              width="550px"
              height="380px"
              alt="screen-catcher"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="lg:mb-156 mx-auto mb-56 max-w-[1100px] px-16 lg:px-0">
      <div class="rounded-12 shadow-landing-1 p-8 lg:flex lg:p-0 lg:shadow-none">
        <div class="mb-24 lg:mb-0 lg:w-1/2">
          <NuxtImg
            src="/landing/screen-catcher-2.webp"
            class="rounded-14 w-full"
            sizes="lg:488px sm:350px md:450px"
            loading="lazy"
            width="550px"
            height="482px"
            alt="what is mili?"
          />
        </div>
        <div class="lg:w-1/2">
          <div class="lg:rounded-24 lg:shadow-landing-1 lg:p-56">
            <h3 class="text-h3-bold text-raspberry lg:text-h1-bold mb-12 lg:mb-32">
              What is Mili?
            </h3>
            <p>
              Welcome to Mili, video chat app that makes it easy to meet new people online. Have
              lots of fun in private video calls, live video chat, or comfy text chat. Practise
              foreign languages & boost your communication skills. Feel totaly connected to the
              world even in times of social distancing!
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="lg:mb-156 mx-auto mb-56 flex max-w-[1100px] px-16 lg:px-0">
      <div class="rounded-12 shadow-landing-1 p-8 lg:flex lg:p-0 lg:shadow-none">
        <div class="order-1 mb-12 lg:order-2 lg:mb-0 lg:w-1/2 lg:pl-16 lg:text-right">
          <NuxtImg
            src="/landing/screen-catcher-3.webp"
            loading="lazy"
            sizes="lg:536px sm:350px md:450px"
            class="w-full"
            width="534px"
            height="525px"
            alt="secure video chat"
          />
        </div>
        <div class="lg:w-1/2 lg:pr-16">
          <div class="lg:rounded-24 lg:shadow-landing-2 lg:p-56">
            <h3 class="text-h3-bold text-raspberry lg:text-h1-bold mb-12 lg:mb-32">
              Secure Video Chat
            </h3>
            <p>
              At Mili we care about your privacy a lot! We know exactly what it takes to make a
              video chat safe and secure for all. Manual profile and photo moderation ensure
              community policy eligibility. Advanced user trust-rating system - no fake profiles or
              scammers! Easy to use report system at every stage of chatting.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="mb-100 mx-auto max-w-[1000px] px-16 text-center lg:px-0">
      <div class="rounded-24 bg-landing-img-3 pb-24">
        <h3 class="text-h3-bold text-raspberry lg:text-h1-bold mb-12 lg:mb-32">
          How does it work?
        </h3>
        <p class="lg:w-700 mx-24 mb-36 lg:mx-auto lg:mb-56">
          Mili internal algorithm connects you with a user based on interest matching and user
          rating. If you don't like your chat-mate, simply start the new chat
        </p>
        <div class="mb-24">
          <NuxtImg
            src="/landing/screen-catcher-4.webp"
            loading="lazy"
            sizes="lg:999px sm:350px md:450px"
            width="999px"
            height="513px"
            alt="The man with phone in his hands"
          />
        </div>
        <NuxtLink
          class="bg-raspberry text-h3-medium lg:w-480 mx-auto inline-flex h-[50px] items-center justify-center rounded-full px-24 text-white hover:text-white lg:flex"
          :to="toReg"
          @click="onClickButtonSignup(2)"
        >
          Start Chat
        </NuxtLink>
      </div>
    </section>

    <section class="mx-auto max-w-[1000px] px-16 text-center">
      <h3 class="text-h3-bold text-raspberry lg:text-h1-bold mb-12 lg:mb-32">Why Mili?</h3>
      <p class="lg:w-700 mx-24 mb-36 lg:mx-auto lg:mb-56">
        Mili offers you a high-quality video and a full-screen experience. Enjoy outstanding and
        convenient design - video chat at maximum!
      </p>
      <div class="mb-24 lg:flex">
        <div class="mb-24 lg:mb-0 lg:w-1/2 lg:pr-12">
          <div
            class="h-300 rounded-20 shadow-landing-4 flex flex-col items-center justify-between bg-white px-32 pb-16 pt-32 lg:px-40 lg:pb-16 lg:pt-40"
          >
            <div>
              <h4 class="text-h4-bold lg:text-h3-bold mb-12">Easy to Use</h4>
              <p>Download. Fill out the profile. You're in the game! It's that simple!</p>
            </div>
            <img
              class="w-140"
              src="/landing/special-1.svg?url"
              loading="lazy"
              width="100"
              height="100"
              alt="Easy to use"
            />
          </div>
        </div>
        <div class="lg:w-1/2 lg:pl-12">
          <div
            class="h-300 rounded-20 shadow-landing-4 flex flex-col items-center justify-between bg-white px-32 pb-16 pt-32 lg:px-40 lg:pb-16 lg:pt-40"
          >
            <div>
              <h4 class="text-h4-bold lg:text-h3-bold mb-12">International Community</h4>
              <p>Video chat with men and women all over the world!</p>
            </div>
            <img
              class="w-140"
              width="100"
              height="100"
              src="/landing/special-2.svg?url"
              loading="lazy"
              alt="International community"
            />
          </div>
        </div>
      </div>
      <div class="mb-24 lg:flex">
        <div class="mb-24 lg:mb-0 lg:w-1/2 lg:pr-12">
          <div
            class="h-300 rounded-20 shadow-landing-4 flex flex-col items-center justify-between bg-white px-32 pb-16 pt-32 lg:px-40 lg:pb-16 lg:pt-40"
          >
            <div>
              <h4 class="text-h4-bold lg:text-h3-bold mb-12">Awesome Gifts</h4>
              <p>Surprise your friends with specially designed paid gifts!</p>
            </div>
            <img
              class="w-140"
              width="100"
              height="100"
              src="/landing/special-3.svg?url"
              loading="lazy"
              alt="Awesome gifts"
            />
          </div>
        </div>
        <div class="lg:w-1/2 lg:pl-12">
          <div
            class="h-300 rounded-20 shadow-landing-4 flex flex-col items-center justify-between bg-white px-32 pb-16 pt-32 lg:px-40 lg:pb-16 lg:pt-40"
          >
            <div>
              <h4 class="text-h4-bold lg:text-h3-bold mb-12">Gender Filter</h4>
              <p>
                Choose the gender you want to connect to, an additional filter to have even more
                fun!
              </p>
            </div>
            <img
              class="w-140"
              width="100%"
              height="100%"
              src="/landing/special-4.png"
              loading="lazy"
              alt="Gender filter"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="min-h-400 bg-landing-img-2 lg:min-h-700 flex items-center text-center">
      <div class="mx-auto max-w-[1100px]">
        <h3 class="text-h3-bold text-raspberry lg:text-h1-bold mb-24 lg:mb-32">Join Now!</h3>
        <p class="w-340 lg:w-700 mx-auto mb-32 px-16 lg:mb-72 lg:px-0">
          Don’t be shy, join and help us to build a global community of over 1M open-minded people.
          Get into the epicenter of joy & start meeting incredible people now!
        </p>
        <NuxtLink
          class="bg-raspberry text-h3-medium lg:w-480 mx-auto mb-4 inline-flex h-[50px] items-center justify-center rounded-full px-24 text-white hover:text-white lg:flex"
          :href="toReg"
          @click="onClickButtonSignup(3)"
        >
          Start Chat
        </NuxtLink>
      </div>
    </section>

    <FooterComp />
  </div>
</template>
